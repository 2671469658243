
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import dashboard from '@/modules/Dashboard';
import { RankType } from '@/api/graphQL/graphNodes/types';
import RankRequirementManager from '@/utils/rankRequirementManager';
import { RankRequirementType } from '@/utils/graphql-mock-server/types';
import StatItemLayout from '../StatItemLayout/index.vue';

@Component({
    components: {
        StatItemLayout,
    },
})
export default class PBV extends Vue {
    @Get(dashboard, 'totals.pv') private pv!: number;

    @Prop() private requirements!: RankRequirementType[];

    @Prop() private rank!: RankType;

    get title(): string {
        return RankRequirementManager.getRankRequirementLabel(
            RankRequirementManager.rankRequirementMetricConstants.pv,
            this.$t,
        ).title;
    }

    get display150req(): boolean {
        const { label } = this.rank;
        return label === 'temporary.rank.customer' || label === 'temporary.rank.teamLeader';
    }

    get pbvRequirement(): string|null {
        const pv = this.requirements
            .find((item) => item.metric === RankRequirementManager
                .rankRequirementMetricConstants.pv);

        return pv ? pv.value : null;
    }

    get tooltipText(): string {
        if (this.display150req) {
            return this.$t('view.dashboard.stats.pvTooltip150');
        }

        const { pbvRequirement, rank } = this;

        // @ts-ignore
        return this.$t('view.dashboard.stats.pvTooltip', {
            rankLabel: rank.label,
            count: Number(pbvRequirement) || '',
        });
    }

    get progressValue(): number|null {
        const x = this.pv * 100;

        if (this.requirements.length === 0) {
            return null;
        }

        if (!this.pbvRequirement) {
            return null;
        }

        if (Number(this.pbvRequirement) === 0) {
            return 0;
        }

        return x / Number(this.pbvRequirement);
    }
}
