

import { Component, Vue } from 'vue-property-decorator';
import calendar from '@/modules/Calendar';
import auth from '@/modules/Auth';
import { Get } from '@/utils/vuex-module-mutators';
import { CALENDAR_TIME_FRAME_OPTIONS } from '@/modules/Calendar/constants';
import TimeControls from './Time/Controls/index.vue';
import TimeFrameSelect from './ViewControls/TimeFrameSelect/index.vue';
import DisplayedEventsTypeSelect from './ViewControls/DisplayedEventsTypeSelect/index.vue';
import TypeFilters from './FilterContainer/TypeFilters/index.vue';

@Component({
    components: {
        TimeControls,
        TimeFrameSelect,
        DisplayedEventsTypeSelect,
        TypeFilters,
    },
})
export default class CalendarMobileControls extends Vue {
    @Get(calendar, 'calendarTimeFrame') calendarTimeFrame!: string;

    get isCustomer() {
        return auth.isCustomer;
    }

    get selectedDayDate() {
        return calendar.getSelectedDayDisplayDate;
    }

    get selectedMonthDate() {
        return calendar.getSelectedMonthDate;
    }

    get isDailyView() {
        return this.calendarTimeFrame === CALENDAR_TIME_FRAME_OPTIONS.day;
    }
}
